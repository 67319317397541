html {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Space',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  background-color: #121212;
  margin: 0;
}

body {
  min-height: 100vh;
  background: linear-gradient(135deg, rgba(33,33,33,1) 0%, rgba(25,25,25,1) 50%, rgba(18,18,18,1) 100%);
}

body::backdrop{
  background-color: #121212;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
